import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/admin";
const STORE_NAME = "documents";

export const uploadDocument = async (modelName, item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/document/upload?model_name=${modelName}`;
    const params = item;
    const headers = { "Content-Type": "multipart/form-data" };
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to create ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to create ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};
